import { RemovableRef, StorageSerializers, useLocalStorage } from "@vueuse/core";
import { AxiosResponse } from "axios";
import { defineStore } from "pinia";
import { getNow, prettyResponse } from ".";
import { ListResponse, RequestResponse } from "@/models/UserSearchModel";
import { LocationCalendar } from "@/models/LocationCalendar";
import memoize from "memoizee";
import { LocationViewModel } from "@/models/LocationViewModel";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { EventTemplateListItemViewModel } from "@/models/EventTemplate";

const fn = function(location: string, urlParams: URLSearchParams) {
  return getNow(location,urlParams);
};

const memoized = memoize(fn, { 
  promise: true, 
  maxAge: 60000,
  normalizer: function(args) {

  const params = args[1] as URLSearchParams;
  const str = args[0] + "?" + params.toString();
  // console.debug(str);
  return str;
}});

interface CalendarStoreState {
  calendarCollections: RemovableRef<string[]>;
}

export const useCalendarStore = defineStore('calendar', {
    state: ():CalendarStoreState => ({
      calendarCollections: useLocalStorage("calendarCollections", [],{ serializer: StorageSerializers.object,  mergeDefaults: true  }),
    }),
  
    getters: {
    }, 
  
    actions: {
      async getCalendar(organizationId: string | undefined, 
        locationId: string | undefined, 
        from: Date | undefined,
        days: number | undefined) {
        try {

          const params = new URLSearchParams([
            ['days', (days??1).toString()],
            ['from', (from??new Date()).toISOString()],]);
      
            if(locationId) params.append('locationId',locationId);      
            if(organizationId) params.append('organizationId',organizationId);
           
            const r = await getNow(`Calendar/get`, params) as AxiosResponse<RequestResponse<LocationCalendar>>;

            if(r.data.success===true) {
                return r.data.item;
              } else {
                throw new Error((r as any).data.Message);
              }
          } catch (reason) {
            throw (prettyResponse(reason,undefined));
          }
      },        

      async getCalendarForMemeber(
        organizationId: string | undefined, 
        locationId: string | undefined, 
        from: Date | undefined,
        days: number | undefined,
        userId: number | undefined,
        memberId: string | undefined,
        collections: Array<string> | undefined) {
        try {

          const params = new URLSearchParams([
            ['userId', (userId??0).toString()],
            ['memberId', (memberId??"")],
            ['days', (days??1).toString()],
            ['from', (from??new Date()).toISOString()],]);
      
            if(locationId) params.append('locationId',locationId);
            if(organizationId) params.append('organizationId',organizationId);
            if(collections) params.append('collections',collections.toString());
           
            const r = await getNow(`Calendar/ListForMember`, params) as AxiosResponse<RequestResponse<LocationCalendar>>;

            if(r.data.success===true) {
                return r.data.item;
              } else {
                throw new Error((r as any).data.Message);
              }
          } catch (reason) {
            throw (prettyResponse(reason,undefined));
          }
      },   

      async getLocation(id: string) {
        try {
            const params = new URLSearchParams([ ['id', id]]);
            const r = await memoized("Location/Get",params) as AxiosResponse<LocationViewModel>;

            if(r.data.id === id){
              return r.data;
            } else {
              throw "Not found";
            }

        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },


      async getCategory(id: string) {
        try {
            const r = await getNow(`Categories/${id}`, undefined) as AxiosResponse<EventCategoryViewModel>;
            return r.data;
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },  

      async getCategories(organizationId: string) {
        const params = new URLSearchParams([['organizationId', organizationId]]);
        try {
            const r = await getNow(`Categories`, params) as AxiosResponse<ListResponse<EventCategoryViewModel>>;

            if(r.data.success===true){
              return r.data.items.sort((i,j)=>(i.orderKey ?? 0) - (j.orderKey ?? 0));
            } else {
              throw r.data.message;
            }
        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },  

      async getEventTemplateOptions(ticketTypeId: string, eventTemplateId: string|undefined) {
        const params = new URLSearchParams([['ticketTypeId', ticketTypeId]]);
        if(eventTemplateId) params.append('eventTemplateId',eventTemplateId);
        
        try {

          const r = await getNow(`eventtemplate/SearchOptions`, params) as AxiosResponse<Array<EventTemplateListItemViewModel>>;
          const validatedResponse = new Array<EventTemplateListItemViewModel>();
          r.data.forEach(i=>validatedResponse.push((new EventTemplateListItemViewModel).fromResponse(i)));

          validatedResponse.sort((i,j)=>('' + i.title ?? "").localeCompare(j.title ??""));
          return validatedResponse;

        } catch (reason) {
            throw (prettyResponse(reason,undefined));
        }
      },  

      // async printReceipt(cartId: string) {
      //   const store = useStore();
      //   try {
      //       const params = new URLSearchParams([['cartId', cartId], ['organizationId', store.state.organization.id]]);
      //       await getNow(`shoppingcart/PrintReceipt`, params);
      //       return;
      //   } catch (reason) {
      //       throw (prettyResponse(reason,undefined));
      //   }
      // },

      // async emailReceipt(cartId: string, email: string) {
      //   const store = useStore();
      //   try {
      //       const params = new URLSearchParams([['cartId', cartId], ['email', email]]);
      //       await getNow(`shoppingcart/EmailReceipt`, params);
      //       return;
      //   } catch (reason) {
      //       throw (prettyResponse(reason,undefined));
      //   }
      // },

    }
  })