
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonRow,
  IonCol,
IonText,
} from "@ionic/vue";
import { defineComponent, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { useErrorBox } from "@/components/errorBox";
import { MessageDto } from "@/models/core/Messaging";
import { useUserStore } from "@/store/userStore";

export default defineComponent({
  name: "WriteMessagePage",
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    // IonInput,
    IonItem,
    // IonButton,
    IonRow,
    IonCol,
    IonText,
    // IonTextarea,
    // IonCheckbox,
    // IonSelect,
    // IonSelectOption,
  },
  setup() {
    const router = useRouter();
    const userStore = useUserStore();
    const {showError} = useErrorBox();
    const message: Ref<MessageDto|undefined> = ref(undefined);
    const toList: Ref<string[]> = ref([]);

    onMounted( async () => {
      toList.value = router.currentRoute.value.params.to.toString().split(",");
      try {
        // message.value = await userStore.getMessage(id);
      } catch (error) {
        showError(error as string);
      }
    });
    

    return {
      toList, message,
    };
  }
});
