
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  IonInput,
  IonItem,
  IonList,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonText,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonPopover,
} from "@ionic/vue";

import { computed, defineComponent, onMounted, Ref, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { trashOutline, chevronBackOutline, checkmarkOutline,pencilOutline, addOutline, removeOutline, } from "ionicons/icons";
import { useStore, ActionTypes } from "@/store";
import { ProductViewModel, VatGroupViewModel } from "@/models/ProductViewModel";
import { EventCategoryViewModel } from "@/models/EventCategoryViewModel";
import { MoneyAccountViewModel2, ShoppingCartLineViewModel, ShoppingCartViewModel } from "@/models/ShoppingCart";
import { UserPaymentViewModel } from "@/models/UserDetailedViewModel";
import { useErrorBox } from "@/components/errorBox";
import { createGuid, roundToCent } from "@/common/utils";
import { useFinancialStore } from "@/store/financialStore";
import { useCalendarStore } from "@/store/calendarStore";


export default defineComponent({
  name: "CashRegister",
  components: {
    // IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonItem,
    IonList,
    IonLabel,
    IonSelect,
    IonSelectOption,
    IonText,
    IonIcon,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonPopover,
    IonModal,
    // IonBackdrop,

    // IonMenu,
    // IonModal
    // IonBackdrop,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const financial = useFinancialStore();
    const calendarStore = useCalendarStore();
    const {showError} = useErrorBox();
    const id: string = router.currentRoute.value.params["id"].toString();

    const allProducts: Ref<Array<ProductViewModel>> = ref([]);
    const allCategories: Ref<Array<EventCategoryViewModel>> = ref([]);
    const allVatGroups: Ref<Array<VatGroupViewModel>> = ref([]);
    const cartLines: Ref<Array<ShoppingCartLineViewModel>> = ref([]);
    const filterTxt: Ref<string> = ref("");
    const categoryId: Ref<string|undefined> = ref(undefined);
    const currentCart: Ref<ShoppingCartViewModel|undefined> = ref(undefined);
    const payments: Ref<Array<UserPaymentViewModel>> = ref([]);

    const receivingMoney: Ref<number> = ref(0);
    const moneyReceiveVisible: Ref<boolean> = ref(false);
    const editingLine: Ref<ShoppingCartLineViewModel|undefined> = ref(undefined);
    const editingLineOpen = ref(false);

    const organisation = computed(() => store.getters.organisation);
    const accounts = financial.getAccounts;
  
    const totalUnpaid = computed(() => {
      let sum = 0;
      cartLines.value.forEach(i=>sum = sum + roundToCent(((i.quantity??1)*((i.price ?? 0)-(i.discount??0)))));
      payments.value.forEach(i=>sum = sum - roundToCent(i.fixedAmount));
      if(Math.abs(sum) < 0.01)
        return 0;
      return sum;
      });




    function sortAndFilterCategories(items: Array<EventCategoryViewModel>){
       const list = items
        .filter((vm) => { return vm.active === true && vm.orderKey !== 0;})
        .sort((a,b) => { return (a.orderKey ?? 0) - (b.orderKey ?? 0);});
        return list;
    }

    const getProducts = () => {
      allProducts.value = [];
      allVatGroups.value = [];
      store.dispatch(ActionTypes.GET_PRODUCTS, undefined)
      .then((result) => {
        if(result) {
          allProducts.value=result.items.filter(i => i.isActive);
          allVatGroups.value=result.vatGroups;
        }
      })
      .catch((err) => { showError(err); });
      };


    watch(
      () => organisation.value.id, (id, _oldId) => {
        if(!id) return;
        getProducts(); 
        });


    onMounted(() => {
      getProducts(); 

    calendarStore.getCategories(store.state.organization.id)
      .then((result) => {
        if(result){
          allCategories.value = result;
          
          // Select first one
          const sortedList = sortAndFilterCategories(result);
          if(sortedList.length > 0){
            categoryId.value = sortedList[0].id;
          }
        }
      })
      .catch((err) => {
        console.log(err);
        showError(err.message, true);
      });

    if(id && id.indexOf('-')>0) {
      financial.getShoppingCart(id)
        .then((result) => {
          if(result){
            currentCart.value = result;
            cartLines.value = [];
            currentCart.value.shoppingCartLines?.forEach(line=>{
              cartLines.value.push(line);
            });
            payments.value = [];
            currentCart.value.userPayment?.forEach(line=>{
              // debugger;
              if(line.account.name !== "-")
                payments.value.push(line);
            });

            //currentCart.value.userProfile

            }
        })
        .catch((_err) => {
          router.replace('/main/home');
        });
    }

    });

    const products = computed(() => allProducts.value.filter((vm, _index) => {
      if(filterTxt.value && filterTxt.value.length > 0) {
        const txtLower = filterTxt.value.toLowerCase();
        return (vm.name.toLowerCase().indexOf(txtLower) >= 0 || 
                vm.code.toLowerCase().indexOf(txtLower) >= 0);
       } else if( categoryId.value){
         return vm.categoryIdList.indexOf(categoryId.value) >= 0;
      } else {
        return true;
      }
    }).sort((a,b) => { return b.orderKey - a.orderKey;}));


const readOnly = computed(()=> currentCart.value?.state === 'CLOSED');

    const totalPrice = computed(() =>{
      let total = 0;
      cartLines.value.forEach((i)=>
      {
        const singlePrice = ((i.price??0) - (i.discount??0))*(i.quantity??0);
        total += roundToCent(singlePrice);
      });
      return total;
    });

    const allOption = new EventCategoryViewModel();
      allOption.title = "kaikki";

    const categories = computed(() => {
      const list = sortAndFilterCategories(allCategories.value);
      //list.unshift(allOption);
      return list;
    });

    const selectCategory = (message: CustomEvent) => {
      categoryId.value = message.detail.value;
    };

    const addNewLine = (product: ProductViewModel ) => {
      const line = new ShoppingCartLineViewModel();
      line.id = createGuid();
      line.price = product.price;
      line.product = product;
      line.quantity = 1;
      line.vatPersentage = allVatGroups.value.find(i => i.id === product.vatGroupId)?.persentage ?? 0
      line.name = product.name;
      cartLines.value.push(line);
    }

    const removePayment = (payment: UserPaymentViewModel) => {
      const index = payments.value.findIndex((i)=>i.id === payment.id);
      payments.value.splice(index, 1); 
    }

    const safeToFloat = (myVar: any) => {
      if (typeof myVar === 'string' || myVar instanceof String){
        const s = (myVar as string).replace(',','.');
        if(s.length === 0) { return 0; }
        return Number.parseFloat(s);
      } else if (typeof myVar === 'number') {
        return myVar as number;
      }
      return 0;
    }

    const addPayment = (selectedAccount: MoneyAccountViewModel2) => {

      const p = new UserPaymentViewModel();
      p.amount = safeToFloat(receivingMoney.value);
      p.account = selectedAccount;
      p.added = new Date();
      p.id = createGuid();
      p.source = selectedAccount.type;


      if (p.amount === 0) {
        const a = Math.max(totalUnpaid.value, 0);
        p.amount = Math.round(a * (1.0 / selectedAccount.roundingSize)) / (1.0 / selectedAccount.roundingSize);       
      }

      let index = 0;
      while (index < payments.value.length && (payments.value[index].account?.orderNumber??0)< selectedAccount.orderNumber){
        index++;
      }

      p.update();
      payments.value.splice(index, 0, p);
      receivingMoney.value = 0;
    }
    
    const clearCart = () => {
      currentCart.value = undefined;
      payments.value = [];
      cartLines.value = [];
      moneyReceiveVisible.value = false;
    }

    const currentShoppingCartToViewModel = (type: string) => {  
      let c: ShoppingCartViewModel|undefined = undefined;

      if (!currentCart.value) {
        c = new ShoppingCartViewModel();
        c.id = createGuid();
        c.created = new Date();
        c.state = "OPEN";
        c.shoppingCartLines = [];
        c.userPayment = [];
        c.type = type;        
        // c.userProfile = CurrentCustomer;

      } else {
        c = new ShoppingCartViewModel();
        c.id = currentCart.value.id;
        c.created = currentCart.value.created;
        c.state = currentCart.value.state;
        c.type = currentCart.value.type;
        c.userProfile = currentCart.value.userProfile;
        c.shoppingCartLines = [];
        c.userPayment = [];
        if(!c.type || !c.number){ c.type = type; }
      }

      c.organizationId = organisation.value.id;

      cartLines.value.forEach(i=>{ 
        if(c?.shoppingCartLines)
          c.shoppingCartLines.push(i);});

       payments.value.forEach(i=>{ 
        if(c?.userPayment)
          c.userPayment.push(i);});

      return c;
    }

    const takeFirst = () => {
      if(products.value.length>0){
        addNewLine(products.value[0]);
        filterTxt.value = "";
      }
    }


    const moneyReceived = (success: boolean) =>{
      moneyReceiveVisible.value = false;      
      
      if(!success)
        return;
      
      const c = currentShoppingCartToViewModel("kassa");

      c.userPayment?.forEach((payment)=>{
        if(!payment.received){
          payment.received = new Date();
          payment.organizationId = organisation.value.id;
        }
      });

      if (c.totalUnpaid() > 0.03)
        return;
      
      c.state = "CLOSED";

      financial.saveShoppingCart(c)
        .then((_result) => {
          if(id && id.indexOf('-') > 0 && currentCart.value)
                router.replace('/customer/'+currentCart.value.userProfile?.userId);          
          clearCart();
        })
        .catch((err) => {
          showError(err,true);
          return;
        });
    };

    const leaveOpen = () =>{

      if(!currentCart.value)
        return;

      financial.saveShoppingCart(currentCart.value)
        .then((_result) => {
          if(id && id.indexOf('-')>0 && currentCart.value)
            router.replace('/customer/'+currentCart.value.userProfile?.userId);
          clearCart();
        })
        .catch((err: string) => {
          showError(err,true);
          return;
        });
    };

    const isExisting = computed(() => {
      return currentCart.value && currentCart.value.id && currentCart.value.id.indexOf('-')>0;
    });

    const editLine = (line: ShoppingCartLineViewModel) => {
      editingLine.value = line;
      editingLineOpen.value = true;
    };

    const doneEditingLine = () => {
      if(!editingLine.value)
        return;

      // debugger;

      if(!editingLine.value.discount)
        editingLine.value.discount = 0;

      editingLine.value = undefined;
      editingLineOpen.value = false;
    };

    return {
      addOutline, removeOutline, pencilOutline, checkmarkOutline,chevronBackOutline,trashOutline,
      router,
      products,
      filterTxt,
      categories,
      selectCategory,
      addNewLine,
      cartLines,
      totalPrice,
      clearCart,
      currentCart,
      removePayment,
      addPayment,
      totalUnpaid,
      receivingMoney,
      accounts,
      payments,
      moneyReceived,
      moneyReceiveVisible,
       organisation,
       takeFirst,
       editLine,
       editingLine,doneEditingLine,editingLineOpen, 
       readOnly,
       leaveOpen,isExisting,
    };
  }
});
